import React, { useEffect } from "react";
import axios from "axios";
import VagonStream from "./VagonStreams";
import Page404 from "../../pages/NotFound";
import { isMobileDevice } from "../../utils/utils";
import { getFromLocalStorage } from "../../hooks/useLocalStorage";

const VanityURLRedirects = (props) => {
	const deviceType = isMobileDevice() ? "mobile" : "desktop";
	const [redirectURL, setRedirectURL] = React.useState(null);
	const [error, setError] = React.useState(false);

	useEffect(() => {
		const fetchVanityLink = async (id) => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_SPOTIFY_URL_LIVE}/web/vanity_links/${id}`
				);

				const { streamId, roomId } = response.data;
				const redirectURL = `https://streams.vagon.io/streams/${streamId}?launchFlags=-roomid%20${roomId}%20-userid%20${
					props.userId
				}%20-authtoken%20${getFromLocalStorage(
					"userToken"
				)}%20-disableVoiceChat%20true%20-userdevicetype%20${deviceType}`;
				setRedirectURL(redirectURL);
			} catch (error) {
				console.error("Error fetching the vanity link:", error);
				setError(true);
			}
		};

		const pathId = "teddyswims";
		fetchVanityLink(pathId);
	}, []);

	if (error) return <Page404 />;

	return redirectURL ? <VagonStream streamUrl={redirectURL} /> : null;
};

export default VanityURLRedirects;
