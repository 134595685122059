export async function getSpotifyToken(code, urlParams) {
	const token = null;

	if (code && urlParams) {
		// If the code is present, send a request to the backend to fetch the token
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/callback/${code}`
			);

			if (response.ok) {
				const data = await response.json();

				// Store token in local storage
				// setToLocalStorage("accessToken", data.accessToken);
				// setToLocalStorage("refreshToken", data.refreshToken);

				// Remove the code and state parameters from the URL
				urlParams.delete("code");
				urlParams.delete("state");
				const newUrl = `${window.location.origin}${window.location.pathname}`;
				window.history.replaceState({}, "", newUrl);

				return data;
			} else {
				console.error("Failed to fetch the token from the backend");
				return token;
			}
		} catch (error) {
			console.error("Error during token fetch:", error);
			return token;
		}
	} else {
		return token;
	}
}

export async function transferPlayback(device_id, token) {
	try {
		// This is assigning the device id passed as the active spotify application
		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/transferPlayback/${device_id}/${token}`
		);

		return response;
	} catch (error) {
		console.error("Error during transfer playback:", error);
	}
}

export async function resumePlayer(token, contextURI, type) {
	try {
		const body = JSON.stringify({
			accessToken: token,
			contextURI: contextURI,
			type: type,
		});

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: body,
		};

		// This is assigning the device id passed as the active spotify application
		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/resumePlayback`,
			requestOptions
		);

		return response.status;
	} catch (error) {
		console.error("Error during resume player:", error);
	}
}

export async function setRepeat(state, deviceId, token) {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/setRepeat/${state}/${deviceId}/${token}`
		);

		return response;
	} catch (error) {
		console.error("Error during resume player:", error);
	}
}

export async function getProfile(token) {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/profile/${token}`
		);

		if (response.ok) {
			const data = await response.json();
			return data;
		}

		return null;
	} catch (error) {
		console.error("Error during resume player:", error);
	}
}

export async function getSpotifyRefreshToken(refreshToken) {
	const token = null;

	if (refreshToken) {
		// If the code is present, send a request to the backend to fetch the token
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/refreshToken/${refreshToken}`
			);

			if (response.ok) {
				const data = await response.json();

				return data.accessToken;
			} else {
				console.error("Failed to fetch the token from the backend");
				return token;
			}
		} catch (error) {
			console.error("Error during token fetch:", error);
			return token;
		}
	} else {
		return token;
	}
}
