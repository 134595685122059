import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import UserNameIcon from "../../assets/images/username.svg";
import EnterButtonBg from "../../assets/images/PrimaryButton.png";
import {
    getFromLocalStorage,
    setToLocalStorage,
} from "../../hooks/useLocalStorage";
import { signUpUser } from "../../services/UserController";
import { validateUsername } from "../../utils/utils";

const NameCollectionForm = (props) => {
    const [displayName, setDisplayName] = useState(
        getFromLocalStorage("email").split("@")[0]
    );
    const [displayNameError, setDisplayNameError] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("submit-button").click();
        }
    };

    const handleNameSubmitChoice = async () => {
        setIsLoading(true);
        await signUpUser(displayName, props.email).then((res) => {
            if (res) {
                props.setIsLoading(true);
                props.setUserToken(res.token);
                props.setUserId(res.userId);
                setToLocalStorage("userId", res.userId);
                setToLocalStorage("userToken", res.token);

                setTimeout(() => {
                    setToLocalStorage("displayName", displayName);
                    props.setDisplayNameHome(displayName);
                }, 500);
            } else {
                setDisplayNameError("Error signing up.");
                console.log("ERROR SIGNUP FOR USER");
                props.setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        if (displayName === "") {
            setDisplayNameError("");
        } else if (!validateUsername(displayName)) {
            setNameValid(false);
            setDisplayNameError(
                "Choose between 3-20 chars (letters, numbers, underscores, & hyphens)"
            );
        } else {
            setNameValid(true);
            setDisplayNameError("");
        }
    }, [displayName]);

    return (
        <>
            <TitleContainer>
                <PageTitle>What's your name?</PageTitle>
                <PageSubtitle>
                    Choose your in-game name for your avatar
                </PageSubtitle>
            </TitleContainer>
            <UsernameSectionVertical>
                <UsernameSection>
                    <UsernameIconContainer>
                        <Icon src={UserNameIcon} alt="Username Icon" />
                        <UsernameText>USERNAME</UsernameText>
                    </UsernameIconContainer>
                    <Input
                        id="username"
                        placeholder="What is your avatar's name?"
                        type="username"
                        fullWidth
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                    />

                    {!nameValid && (
                        <WarningText>{displayNameError}</WarningText>
                    )}
                    {isLoading && (
                        <div style={{ margin: "5px" }}>
                            <CircularProgress
                                size="30px"
                                sx={{ color: "#ced1d9", opacity: "0.5" }}
                            />
                        </div>
                    )}
                </UsernameSection>
            </UsernameSectionVertical>
            {!displayName || !nameValid ? (
                <GreyBox>
                    <GreyBoxText
                        id="submit-button"
                        onClick={() => {
                            setDisplayNameError(
                                "Valid display Name is required."
                            );
                        }}
                    >
                        Jump In
                    </GreyBoxText>
                </GreyBox>
            ) : (
                <PrimaryButton>
                    <button
                        disabled={isLoading}
                        style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            borderStyle: "none",
                            height: "70px",
                            width: "200px",
                            marginTop: "5px"
                        }}
                        id="submit-button"
                        onClick={() => {
                            handleNameSubmitChoice();
                        }}
                    >
                        <EnterButton src={EnterButtonBg} alt={"Jump in"} />
                    </button>
                </PrimaryButton>
            )}
        </>
    );
};

export default NameCollectionForm;

const TitleContainer = styled.div`
    text-align: center;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    z-index: 5;
    margin: 0 2rem 100px 2rem;

    @media (max-width: 960px) {
        margin-bottom: 30px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        margin-top: 30px;
    }
`;

const PageTitle = styled.h2`
    font-size: 2.5rem;
    margin-bottom: 5px;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    color: #ced1d9;

    @media (max-width: 960px) {
        font-size: 1.3rem;
        padding: 1rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 1.5rem;
    }
`;

const WarningText = styled.p`
    font-size: 1rem;
    align-self: center;
    color: #beaeff;
    font-family: "Barlow", sans-serif;
    font-style: italic;
    padding: 0 2rem;

    @media (max-width: 960px) {
        font-size: 0.5rem;
        padding: 0 0.6rem;
        font-size: 10px;
    }
`;

const PageSubtitle = styled.p`
    font-size: 1.3rem;
    margin-bottom: 20px;
    font-weight: 500;
    color: #ced1d9;
    text-align: center;

    @media (max-width: 960px) {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-bottom: 0px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 1rem;
    }
`;

const UsernameSection = styled.div`
    background: rgba(27, 27, 35, 0.9);
    display: flex;
    align-items: center;
    border: 4px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    width: 50%;
    height: 44px;
    flex-shrink: 0;
    flex: 1;

    @media (max-width: 960px) {
        height: 45px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        height: 45px;
    }
`;

const UsernameSectionVertical = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    height: 54px;
    z-index: 5;
    align-content: center;

    @media (max-width: 960px) {
        height: 35px;
        width: 90%;
        margin-bottom: 1rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        height: 45px;
        width: 70%;
        margin-bottom: 1rem;
    }
`;

const UsernameIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 10px; */
    padding: 10px;

    @media (max-width: 960px) {
        height: 10px;
        width: 10px;
        margin: 10px;
        padding: 10px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        height: fit-content;
        width: fit-content;
        margin: 10px;
        padding: 10px;
    }
`;

const UsernameText = styled.span`
    font-size: 0.5rem;
    margin-top: 2px;
    margin-bottom: 2px;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    color: white;

    @media (max-width: 960px) {
        font-size: 0.4rem;
        margin-top: 0px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 12px;
        width: 90%;
        margin-top: 0px;
    }
`;

const Input = styled.input`
    flex: 1;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    height: 44px;
    width: 64px;
    padding-left: 2rem;
    font-size: 18px;
    font-style: italic;
    font-family: "Barlow", sans-serif;
    color: #9da0a7;

    ::placeholder {
        color: #9da0a7;
        opacity: 50%;

        @media (max-width: 1440px) {
            font-size: 14px;
        }
    }

    @media (max-width: 960px) {
        font-size: 14px;
        padding-left: 10px;
        height: 45px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 18px;
        padding-left: 12px;
        height: 45px;
    }
`;

const Icon = styled.img`
    width: 50px;
    height: 50px;

    @media (max-width: 960px) {
        height: 25px;
        width: 25px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        height: 30px;
        width: 30px;
    }

    @media (min-width: 1440px) {
        height: 35px;
        width: 35px;
    }
`;

const EnterButton = styled.img`
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;

    @media (max-width: 960px) {
        padding-left: 0rem;
    }
`;
const PrimaryButton = styled.button`
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    margin: 1rem;
    z-index: 5;

    @media (max-width: 960px) {
        padding: 0rem;
        position: initial;
        padding: 0rem;
        margin: 0rem;
        height: 90px;
        width: 230px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        padding: 0rem;
        position: initial;
        padding: 0rem;
        margin: 1rem;
    }
`;

const GreyBox = styled.div`
    height: 30px;
    background-color: rgba(80, 77, 89, 1);
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    margin: 1rem;
    z-index: 5;

    @media (max-width: 960px) {
        padding: 0rem;
        position: initial;
        /* margin: 2rem; */
        height: 40px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        position: initial;
        padding: 0 1rem;
        height: 50px;
    }
    @media (min-width: 1441px) {
        margin: 4rem;
    }
`;

const GreyBoxText = styled.span`
    font-size: 1rem;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    color: #9da0a7;
    text-transform: capitalize;

    @media (max-width: 960px) {
        font-size: 0.6rem;
        padding: 0 2rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 1rem;
        padding: 0 2rem;
    }

    @media (min-width: 1441px) {
        font-size: 1.3rem;
    }
`;
