import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
	getFromLocalStorage,
	setToLocalStorage,
} from "../../hooks/useLocalStorage";
import BG from "../../assets/images/teddyswims_bg_desktop_v1.png";
import BGMobile from "../../assets/images/teddyswims_bg_mobile.png";
import IntraverseLogoIcon from "../../assets/images/Intraverse-Logo.png";
import {
	getSpotifyToken,
	getProfile,
	getSpotifyRefreshToken,
} from "../../services/SpotifyController";
import VanityURLRedirects from "../../components/vagon/VanityURLRedirects";
import EmailForm from "../../components/form/EmailForm";
import SpotifyConnected from "../../components/spotify/SpotifyConnected";
import NameCollectionForm from "../../components/form/NameCollectionForm";
import Banner from "../../components/banner/Banner";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import SpotifyLogin from "../../components/form/SpotifyLogin";
import { refreshUserToken } from "../../services/UserController";

const Home = () => {
	const [navigateBackToEmailPage, setNavigateBackToEmailPage] =
		useState(false);
	const [renderComponent, setRenderComponent] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [skipSpotifyLogin, setSkipSpotifyLogin] = useState(false);
	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get("code");

	//Spotify States
	const [spotifyToken, setSpotifyToken] = useState(
		getFromLocalStorage("accessToken")
	);
	const [spotifyNotPremiumAccount, setSpotifyNotPremiumAccount] =
		useState(false);

	//User States
	const [userId, setUserId] = useState(getFromLocalStorage("userId"));
	const [userToken, setUserToken] = useState(
		getFromLocalStorage("userToken")
	);
	const [displayName, setDisplayName] = useState(
		getFromLocalStorage("displayName")
	);
	const [email, setEmail] = useState(getFromLocalStorage("email"));

	useEffect(() => {
		//Spotify logic
		if (code) {
			getSpotifyToken(code, urlParams).then((res) => {
				if (res?.accessToken) {
					getProfile(res.accessToken).then((profile) => {
						//Grabs profile and check if account is premium
						if (profile.product === "free") {
							setSpotifyNotPremiumAccount(true);
							return;
						}

						if (profile.product === "premium") {
							const script = document.createElement("script");
							script.src =
								"https://sdk.scdn.co/spotify-player.js";
							script.async = true;
							document.body.appendChild(script);
							window.onSpotifyWebPlaybackSDKReady = () => {};

							setToLocalStorage("accessToken", res.accessToken);
							setToLocalStorage("refreshToken", res.refreshToken);
							setToLocalStorage("email", profile.email);

							setSpotifyToken(res.accessToken);
						}
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		window.pushHotJarAttributes();

		//Rendering component logic
		if (
			(!email && !spotifyToken && !userId && !userToken) ||
			navigateBackToEmailPage
		) {
			setRenderComponent(<EmailForm setEmail={setEmail} />);
			setNavigateBackToEmailPage(false);
		}

		if (
			!email &&
			!displayName &&
			spotifyToken &&
			!navigateBackToEmailPage
		) {
			setRenderComponent(<SpotifyConnected setEmail={setEmail} />);
		}

		if (email && !displayName) {
			if (spotifyToken || skipSpotifyLogin) {
				setRenderComponent(
					<NameCollectionForm
						spotifyToken={spotifyToken}
						email={email}
						setUserId={setUserId}
						setUserToken={setUserToken}
						setDisplayNameHome={setDisplayName}
						setIsLoading={setIsLoading}
					/>
				);
			}

			if (!spotifyToken && !skipSpotifyLogin) {
				setRenderComponent(
					<SpotifyLogin setSkipSpotifyLogin={setSkipSpotifyLogin} />
				);
			}
		}

		if (userToken && userId) {
			setRenderComponent(<VanityURLRedirects userId={userId} />);
		}

		setTimeout(() => {
			if (isLoading) {
				setIsLoading(false);
			}
		}, 3000);
	}, [spotifyToken, displayName, email, skipSpotifyLogin]);

	useEffect(() => {
		const navigationType = window.performance.navigation.type;

		if (userToken) {
			refreshUserToken(getFromLocalStorage("userId")).then((res) => {
				if (res.token) setToLocalStorage("userToken", res.token);
			});

			if (spotifyToken) {
				getSpotifyRefreshToken(
					getFromLocalStorage("refreshToken")
				).then((token) => {
					if (token) setToLocalStorage("accessToken", token);
					if (
						navigationType ===
						window.performance.navigation.TYPE_NAVIGATE
					) {
						setToLocalStorage("songsPlayed", null);
					}
				});
			}
		}
	}, []);

	return (
		<HomeContainer>
			<IntraverseLogo
				src={IntraverseLogoIcon}
				alt="Intraverse Logo"
				onClick={() => {
					setNavigateBackToEmailPage(true);
					setEmail(null);
				}}
			/>
			<BackgroundOverlay />
			{isLoading ? (
				<Box
					sx={{
						width: "50%",
						color: "#968DFD",
					}}
				>
					<LinearProgress color="inherit" size="lg" />
				</Box>
			) : (
				renderComponent
			)}

			<Banner
				spotifyNotPremiumAccount={spotifyNotPremiumAccount}
				setSpotifyNotPremiumAccount={setSpotifyNotPremiumAccount}
			/>
		</HomeContainer>
	);
};

export default Home;

const HomeContainer = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;

	background-image: url(${BG});
	background-size: cover;
	background-position: center;

	::-webkit-scrollbar {
		display: none;
	}

	// @media (max-height: 500px) {
	// 	height: 100%;
	// }

	@media (max-width: 900px) {
		background-image: url(${BGMobile});
		height: 100vh;
		width: 100vw;
	}
`;

const BackgroundOverlay = styled.div`
	overflow: hidden;
	background-color: #1b1b23;
	opacity: 0.7;
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 2;
`;

const IntraverseLogo = styled.img`
	cursor: pointer;
	position: absolute;
	width: 40px;
	height: 40px;
	top: 20px;
	left: 20px;
	z-index: 5;

	@media (max-width: 960px) {
		width: 25px;
		height: 25px;
		top: 5px;
		left: 5px;
	}
	@media (min-width: 960px) and (max-width: 1440px) {
		width: 30px;
		height: 30px;
		top: 20px;
		left: 20px;
	}
`;
