import "./App.css";
import Home from "./pages/home/Home";

function App() {
    return (
        <Home />
    );
}

export default App;

