import { getFromLocalStorage } from "../hooks/useLocalStorage";
import * as spotifyConstants from "../constants/spotify";

export async function refreshUserToken(userId) {
	try {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ userId: userId }),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/users/auth-session`,
			requestOptions
		);

		if (response.ok) {
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error("Error during refresh token:", error);
		return null;
	}
}

export async function signUpUser(displayName, email) {
	try {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ displayName, email }),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/users/login`,
			requestOptions
		);

		if (response.ok) {
			const data = await response.json();
			return data;
		}

		return null;
	} catch (error) {
		console.error("Error during singn up:", error);
		return null;
	}
}

export const logStreaming = async (song, uri) => {
	const userId = getFromLocalStorage("userId");
	const userSession = getFromLocalStorage("userToken");
	if (!userId) {
		return;
	}

	await fetch(
		`${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/misc/teddys-lounge-spotify-tracking`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + userSession,
			},
			body: JSON.stringify({
				analyticsData: {
					trackId: uri,
					sessionId: userSession,
					userId,
					datetimeStarted: new Date(),
					song,
					durationInSeconds: spotifyConstants.STREAM_SECONDS,
				},
			}),
		}
	);
};

export async function sendTeddyNoteToDb(name, message, userToken) {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_SPOTIFY_URL}/web/users/action-log`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + userToken,
				},
				body: JSON.stringify({
					analyticsData: {
						name: name,
						message: message,
					},
				}),
			}
		);
		if (response.ok) {
			console.log("success", response);
			return response.ok;
		}
	} catch (error) {
		console.error("Error sending message:", error);
		return null;
	}
}
