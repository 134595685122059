import React from "react";
import styled from "@emotion/styled";
import SpotifyLogoIcon from "../../assets/images//spotifyLogo2.png";
import Checkmark from "../../assets/images//Checkmark.svg";
import SpotifyCont from "../../assets/images//spotifyLargeContainer.svg";
import { Link } from "react-router-dom";

const SpotifyLogin = (props) => {
	//All logic below is for the spotify authentication and user sign up
	const handleSpotifyAuth = async () => {
		try {
			window.location.href = `${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/login`;
		} catch (error) {
			console.error("Error during Spotify authentication:", error);
		}
	};

	return (
		<>
			<TitleContainer>
				<PageTitle>Stay up-to-date with Teddy’s tunes</PageTitle>
				<PageSubtitle>
					Connect your Spotify to enjoy the complete immersive
					experience
				</PageSubtitle>
			</TitleContainer>
			<SpotifyContainer>
				<SpotifyInner>
					<SpotifyLogoContainer>
						<SpotifyLogo src={SpotifyLogoIcon} alt="Spotify Logo" />
						<TitleConnectNow onClick={() => handleSpotifyAuth()}>
							Connect Now
						</TitleConnectNow>
					</SpotifyLogoContainer>
					<SpotifyTextContainer>
						<Title>
							Connect your{" "}
							<span
								style={{
									fontWeight: 700,
									color: "#FFE664",
								}}
							>
								Premium Spotify
							</span>{" "}
							to
						</Title>
						<SubtitleContainer>
							<Icon src={Checkmark} alt="Checkmark" />
							<Subtitle>
								Play Teddy Swims songs throughout your gameplay
							</Subtitle>
						</SubtitleContainer>
						<SubtitleContainer>
							<Icon src={Checkmark} alt="Checkmark" />
							<Subtitle>
								Unlock exclusive easter eggs from Teddy
							</Subtitle>
						</SubtitleContainer>
					</SpotifyTextContainer>
				</SpotifyInner>
			</SpotifyContainer>
			<GreyBox>
				<GreyBoxText onClick={() => props.setSkipSpotifyLogin(true)}>
					Skip for now
				</GreyBoxText>
			</GreyBox>
		</>
	);
};

export default SpotifyLogin;

const GreyBox = styled.div`
	/* width: 100px; */
	height: 30px;
	position: absolute;
	bottom: 5px;
	right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4rem;
	z-index: 5;

	@media (max-width: 960px) {
		position: relative;
		padding: 1rem;
		margin: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		padding: 0rem;
		margin: 1rem;
		bottom: 0px;
		right: 10px;
	}

	@media (min-width: 1440px) {
		margin: 3rem;
	}
`;

const GreyBoxText = styled(Link)`
	font-size: 1.6rem;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	color: #9da0a7;
	text-transform: capitalize;
	text-decoration: none;
	width: fit-content;

	@media (max-width: 960px) {
		font-size: 1rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.3rem;
	}

	@media (min-width: 1440px) {
		font-size: 1.9rem;
	}
`;

const SpotifyContainer = styled.div`
	/* background: rgba(255, 255, 255, 0.3); */
	/* padding: 20px; */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 50%;
	height: fit-content;
	z-index: 5;
	justify-content: center;

	@media (max-width: 960px) {
		width: 100%;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 90vw;
	}

	@media (min-width: 1440px) and (max-width: 2000px) {
		width: 60vw;
	}
`;
const SpotifyTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 1rem;
	z-index: 5;
	@media (max-width: 960px) {
		padding: 1rem;
	}
`;
const SpotifyInner = styled.div`
	/* background-image: url(${SpotifyCont}); */
	/* background-size: cover; */
	/* background-size: 100%;
    background-repeat: no-repeat;
    background-position: center; */
	padding-bottom: 1rem;
	margin-top: 1rem;
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgba(27, 27, 35, 0.9);
	display: flex;
	align-items: center;
	border: 4px solid rgba(255, 255, 255, 0.2);
	position: relative;

	@media (max-width: 960px) {
		padding: 5px;
		width: 85%;
		margin-top: 0rem;
	}
	@media (min-width: 960px) and (max-width: 1440px) {
		width: 75%;
		margin-top: 2rem;
		padding-bottom: 0rem;
	}
`;

const Title = styled.h1`
	font-size: 1.5rem;
	margin-bottom: -5px;
	color: white;
	font-family: "Barlow", sans-serif;
	font-style: normal;
	font-weight: 400;
	padding-left: 2rem;
	padding-bottom: 1rem;

	@media (max-width: 960px) {
		font-size: 0.8rem;
		padding-bottom: 0.5rem;
		padding-left: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.2rem;
	}
`;
const Subtitle = styled.p`
	font-size: 1rem;
	margin-left: 1rem;
	margin-bottom: 15px;
	color: white;
	font-family: "Barlow", sans-serif;

	@media (max-width: 960px) {
		font-size: 0.8rem;
		padding-left: 0rem;
		margin-left: 1rem;
		margin-bottom: 0px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.4rem;
	}
`;
const TitleConnectNow = styled.h1`
	cursor: pointer;
	font-size: 1.5rem;
	font-weight: 700;
	color: "#1ED760";
	color: white;
	font-family: "Barlow", sans-serif;
	padding-left: 2rem;

	@media (max-width: 960px) {
		font-size: 1rem;
		padding-left: 1rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.7rem;
		padding-left: 2rem;
	}
`;
const SubtitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0;
`;

const SpotifyLogo = styled.img`
	width: 80px;
	height: 80px;
	align-self: center;

	@media (max-width: 960px) {
		width: 50px;
		height: 50px;
		margin-right: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 60px;
		height: 60px;
	}
`;

const SpotifyLogoContainer = styled.div`
	padding: 3rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (max-width: 960px) {
		padding: 0.5rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		padding: 1rem;
	}
`;

const TitleContainer = styled.div`
	text-align: center;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	z-index: 5;
	/* top: 100px; */
	position: initial;
	display: flex;
	flex-direction: column;

	@media (max-width: 960px) {
		margin-bottom: 10px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		margin-top: 30px;
	}
`;

const PageTitle = styled.h2`
	font-size: 2rem;
	/* margin-bottom: 5px; */
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	color: #ced1d9;

	@media (max-width: 960px) {
		font-size: 1rem;
		padding: 0 1rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.5rem;
	}
`;

const PageSubtitle = styled.p`
	font-size: 1rem;
	/* margin-bottom: 20px; */
	font-weight: 500;
	color: #ced1d9;

	@media (max-width: 960px) {
		font-size: 0.8rem;
		padding: 0.2rem 1rem;
		margin-bottom: 0px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1rem;
	}
`;

const Icon = styled.img`
	width: 15px;
	height: 15px;
	align-self: center;
	padding-left: 2rem;
	// padding-top: 12px;

	@media (max-width: 960px) {
		height: 10px;
		width: 10px;
		padding-left: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		height: 20px;
		width: 20px;
	}

	@media (min-width: 1440px) and (max-width: 2000px) {
		height: 20px;
		width: 20px;
	}
`;
