import React from "react";
import styled from "@emotion/styled";
import SpotifyLogoIcon from "../../assets/images//spotifyLogo2.png";
import Checkmark from "../../assets/images//Checkmark.svg";
import { getFromLocalStorage } from "../../hooks/useLocalStorage";
// import SpotifyCont from "../../assets/images//spotifyLargeContainer.svg";
import EmailButtonIcon from "../../assets/images//Icon-CTA.png";

const SpotifyConnected = ({ setEmail }) => {
	const setEmailFromSpotify = () => {
		setEmail(getFromLocalStorage("email"));
	};

	return (
		<>
			<TitleContainer>
				<PageTitle>Stay up-to-date with Teddy’s tunes</PageTitle>
				<PageSubtitle>
					Connect your Spotify to enjoy the complete immersive
					experience
				</PageSubtitle>
			</TitleContainer>
			<SpotifyContainer>
				<SpotifyInner>
					<SpotifyLogoContainer>
						<SpotifyLogo src={SpotifyLogoIcon} alt="Spotify Logo" />
						<TitleConnected>Spotify Connected</TitleConnected>
					</SpotifyLogoContainer>
					<SpotifyTextContainer>
						<SubtitleContainer>
							<Icon src={Checkmark} alt="Checkmark" />
							<Subtitle>Successfully connected</Subtitle>
						</SubtitleContainer>
					</SpotifyTextContainer>
				</SpotifyInner>
			</SpotifyContainer>
			<EmailButton
				src={EmailButtonIcon}
				alt="Email Button"
				onClick={() => setEmailFromSpotify()}
			/>
		</>
	);
};

export default SpotifyConnected;

const TitleConnected = styled.h1`
	font-size: 2rem;
	font-weight: 700;
	color: #1ed760;
	font-family: "Barlow", sans-serif;
	padding-left: 1rem;

	@media (max-width: 960px) {
		font-size: 1rem;
		padding-left: 1rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 2rem;
		padding-left: 2rem;
	}
`;

const EmailButton = styled.img`
	cursor: pointer;
	height: 80px;
	width: 80px;
	padding-left: 2.5rem;
	position: absolute;
	bottom: 100px;
	right: 100px;
	z-index: 6;

	@media (max-width: 960px) {
		height: 50px;
		width: 50px;
		padding-left: 0rem;
		padding-top: 0.7rem;
		padding: 0rem;
		position: initial;
		margin: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		padding-left: 0.5rem;
		height: 95px;
		width: 95px;
		position: initial;
		padding: 0rem;
		margin: 1rem;
	}
`;

const SpotifyContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 50%;
	z-index: 5;

	@media (max-width: 960px) {
		width: 80%;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 90vw;
	}
`;
const SpotifyTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 5;
`;
const SpotifyInner = styled.div`
	padding: 20px;
	width: 1300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgba(27, 27, 35, 0.9);
	position: relative;
	display: flex;
	align-items: center;
	border: 4px solid rgba(255, 255, 255, 0.2);

	@media (max-width: 960px) {
		padding: 5px;
		width: 90%;
		height: 150px;
	}
	@media (min-width: 960px) and (max-width: 1440px) {
		width: 70%;
		padding: 5px;
	}

	@media (max-height: 450px) {
		height: 110px;
	}
`;

const Subtitle = styled.p`
	font-size: 1.3rem;
	margin-left: 2rem;
	margin-bottom: 15px;
	color: white;
	font-family: "Barlow", sans-serif;

	@media (max-width: 960px) {
		font-size: 0.8rem;
		padding-left: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1rem;
	}
`;
const SubtitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0;
	margin-bottom: 2rem;

	@media (max-width: 960px) {
		margin-bottom: 5px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		margin-top: 10px;
		margin-bottom: 1rem;
	}
`;

const SpotifyLogo = styled.img`
	width: 80px;
	height: 80px;
	align-self: center;

	@media (max-width: 960px) {
		width: 50px;
		height: 50px;
		margin-right: 0rem;
	}
`;

const SpotifyLogoContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;

	@media (max-width: 960px) {
		width: 50px;
		height: 50px;
		margin-right: 0rem;
		padding: 0rem;
	}
	@media (min-width: 960px) and (max-width: 1440px) {
		padding: 1rem;
		margin-top: 1rem;
	}
`;

const TitleContainer = styled.div`
	text-align: center;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	z-index: 5;
	/* top: 100px;
    position: absolute; */
	position: initial;
	display: flex;
	flex-direction: column;

	@media (max-width: 960px) {
		margin-bottom: 10px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		margin-top: 30px;
	}
`;

const PageTitle = styled.h2`
	font-size: 2rem;
	margin-bottom: 5px;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	color: #ced1d9;

	@media (max-width: 960px) {
		font-size: 1.5rem;
		padding: 0px 5px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 2rem;
	}
`;

const PageSubtitle = styled.p`
	font-size: 1.5rem;
	margin-bottom: 20px;
	font-weight: 500;
	color: #ced1d9;

	@media (max-width: 960px) {
		font-size: 1rem;
		padding: 0.5rem 1rem;
		margin-bottom: 0px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.2rem;
	}
`;

const Icon = styled.img`
	width: 25px;
	height: 25px;
	align-self: center;
	padding-left: 2rem;
	padding-top: 12px;

	@media (max-width: 960px) {
		width: 15px;
		height: 15px;
		padding-top: 0px;
		padding-left: 0rem;
	}
`;
