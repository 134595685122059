import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import Page404 from "./pages/NotFound";

const Root = () => {
	return (
		<HelmetProvider>
			<Router>
				<Routes>
					<Route path="/" element={<App />} />
				</Routes>
			</Router>
		</HelmetProvider>
	);
};

export default Root;
