import React, { useEffect, useRef, useState } from "react";
import { resumePlayer } from "../../services/SpotifyController";
import PropTypes from "prop-types";
import { isMobileDevice } from "../../utils/utils";
import * as spotifyConstants from "../../constants/spotify";
import { getFromLocalStorage } from "../../hooks/useLocalStorage";
import SpotifyInterface from "../spotify/SpotifyInterface";

export const sendVagonMessage = (message) => {
	console.log("Connection Status : " + window.Vagon.isConnected);
	window.Vagon.sendApplicationMessage(message);
};

const VagonStream = ({ streamUrl }) => {
	const isMobile = isMobileDevice();
	const [playingMusic, setPlayingMusic] = useState(false);
	const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
	const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
	const [openContestModalState, setOpenContestModalState] = useState(false);
	const [landscape, setLandscape] = useState(false);
	const elementRef = useRef(null);
	const intervalIdRef = useRef(null);

	const handleButtonClick = () => {
		const escapeKeyEvent = new KeyboardEvent("keydown", {
			key: "Escape",
		});

		document.dispatchEvent(escapeKeyEvent);
	};

	const openTeddyNoteModal = () => {
		handleButtonClick();
		setTeddyNoteOpen((prev) => !prev);
		handleButtonClick();
	};
	const openContestModal = () => {
		handleButtonClick();
		setOpenContestModalState((prev) => !prev);
		handleButtonClick();
	};

	const openTeddySelfiePopupModal = () => {
		handleButtonClick();
		setOpenTeddySelfiePopup((prev) => !prev);
		handleButtonClick();
	};

	const playMusic = () => {
		setPlayingMusic((prev) => !prev);
	};

	const startInterval = () => {
		if (!intervalIdRef.current) {
			intervalIdRef.current = setInterval(() => {
				// console.log("Focus");
				window.Vagon?.focusIframe();
			}, 1000);
		}
	};

	const stopInterval = () => {
		if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		}
	};

	const loadedScript = () => {
		let vagonPlayingMusic = false;

		startInterval();

		// Prints out the message sent from the application
		window.Vagon.onApplicationMessage((evt) => {
			const message = evt.message.toString();
			console.log("EVENT MESSAGE: " + message, evt.message);

			if (spotifyConstants.spotifyTracks.hasOwnProperty(message)) {
				if (vagonPlayingMusic === false) {
					// const type = message.includes(spotifyConstants.TRACK)
					// 	? spotifyConstants.TRACK
					// 	: spotifyConstants.ALBUM;
					const type = spotifyConstants.ALBUM;

					resumePlayer(
						getFromLocalStorage("accessToken"),
						spotifyConstants.spotifyTracks[message],
						type
					).then(() => {
						vagonPlayingMusic = true;
						playMusic();
					});
				}
				return;
			}

			if (message === spotifyConstants.OPEN_TEDDY_CONTEST) {
				openContestModal();
			}
			if (message === spotifyConstants.OPEN_TEDDY_NOTE) {
				openTeddyNoteModal();
			}
			if (message === spotifyConstants.OPEN_TEDDY_SELFIE) {
				openTeddySelfiePopupModal();
			}
		});
	};

	const enterFullScreen = () => {
		if (elementRef.current) {
			if (elementRef.current.requestFullscreen) {
				elementRef.current.requestFullscreen();
			} else if (elementRef.current.mozRequestFullScreen) {
				// Firefox
				elementRef.current.mozRequestFullScreen();
			} else if (elementRef.current.webkitRequestFullscreen) {
				// Chrome, Safari, and Opera
				elementRef.current.webkitRequestFullscreen();
			} else if (elementRef.current.msRequestFullscreen) {
				// Edge
				elementRef.current.msRequestFullscreen();
			} else if (elementRef.current.webkitEnterFullscreen) {
				// For iOS Safari
				elementRef.current.webkitEnterFullscreen();
			} else if (elementRef.current.webkitEnterFullScreen) {
				// For iOS Chrome (tentative)
				elementRef.current.webkitEnterFullScreen();
			}
			updateOrientation();
		}
	};

	const exitFullScreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			// Firefox
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			// Chrome, Safari, and Opera
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			// Edge
			document.msExitFullscreen();
		} else if (document.webkitExitFullscreen) {
			// For iOS Safari
			document.webkitExitFullscreen();
		} else if (document.webkitExitFullScreen) {
			// For iOS Chrome (tentative)
			document.webkitExitFullScreen();
		}
	};

	const updateOrientation = () => {
		// first check to ensure it is mobile user
		if (!isMobile) return;
		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const aspectRatio = width / height;
		const iframeElement = document.getElementById("vagonFrame");

		// second check to ensure aspect Ratio is below 1
		if (aspectRatio < 1) {
			iframeElement.classList.add("force-landscape");
			iframeElement.style.removeProperty("width");
			iframeElement.style.removeProperty("height");
			setLandscape(true);
		} else {
			iframeElement.classList.remove("force-landscape");
			iframeElement.style.width = "100vw";
			iframeElement.style.height = "100vh";
			setLandscape(false);
		}
	};

	useEffect(() => {
		//Adding Vagon script
		const head = document.querySelector("head");
		const script = document.createElement("script");
		script.setAttribute("src", "https://app.vagon.io/vagonsdk.js");
		script.addEventListener("load", () => {
			loadedScript();
		});
		head.appendChild(script);

		window.addEventListener("resize", updateOrientation);

		return () => window.removeEventListener("resize", updateOrientation);
	}, []);

	useEffect(() => {
		if (teddyNoteOpen || openContestModalState || openTeddySelfiePopup) {
			stopInterval();
		} else {
			startInterval();
		}
	}, [teddyNoteOpen, openContestModalState, openTeddySelfiePopup]);

	return (
		<div
			className="iframe-container"
			style={{ width: "100vw", height: "100vh", zIndex: 5 }}
			ref={elementRef}
		>
			<SpotifyInterface
				enterFullScreen={enterFullScreen}
				exitFullScreen={exitFullScreen}
				landscape={landscape}
				teddyNoteOpen={teddyNoteOpen}
				setTeddyNoteOpen={setTeddyNoteOpen}
				openTeddySelfiePopup={openTeddySelfiePopup}
				setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
				playingMusic={playingMusic}
				openContestModalState={openContestModalState}
				setOpenContestModalState={setOpenContestModalState}
				updateOrientation={updateOrientation}
			/>
			<iframe
				id="vagonFrame"
				title="Vagon Stream Content"
				allow="clipboard-read *; clipboard-write *; encrypted-media *;"
				style={{ width: "100vw", height: "100vh", border: 0 }}
				src={streamUrl}
				sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
			/>
		</div>
	);
};

VagonStream.propTypes = {
	streamUrl: PropTypes.string.isRequired,
};

export default VagonStream;
