import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import SpotifyLogoIcon2 from "../../assets/images//spotifyLogo2.png";
import EmailIcon from "../../assets/images//email.svg";
import EmailButtonIcon from "../../assets/images//Icon-CTA.png";
import EmailButtonIconGrey from "../../assets/images//Icon-CTAgrey.png";
import { isValidEmail } from "../../utils/utils";
import {
	setToLocalStorage,
	getFromLocalStorage,
} from "../../hooks/useLocalStorage";

const EmailForm = ({ setEmail }) => {
	const [emailForm, setEmailForm] = useState(
		getFromLocalStorage("email") || ""
	);
	const [emailValid, setEmailValid] = useState(false);
	const [emailError, setEmailError] = useState("");

	const handleInputKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			document.getElementById("submit-button").click();
		}
	};

	const handleEmailSave = async () => {
		if (!validateEmail()) return;
		setToLocalStorage("email", emailForm);
		setEmail(emailForm);
	};

	const validateEmail = () => {
		if (!emailForm || !emailForm.trim()) {
			setEmailError("Email is required");
			return false;
		}

		if (!isValidEmail(emailForm)) {
			setEmailError("Invalid email address");
			return false;
		}
		setEmailValid(true);
		setEmailError("");

		return true;
	};

	useEffect(() => {
		if (isValidEmail(emailForm)) {
			setEmailValid(true);
			setEmailError("");
		} else {
			setEmailValid(false);
		}
	}, [emailForm]);

	//All logic below is for the spotify authentication and user sign up
	const handleSpotifyAuth = async () => {
		try {
			window.location.href = `${process.env.REACT_APP_SPOTIFY_URL}/web/spotify/login`;
		} catch (error) {
			console.error("Error during Spotify authentication:", error);
		}
	};

	return (
		<>
			<TitleContainer>
				<PageTitle>Jump into Teddy’s Lounge</PageTitle>
				<PageSubtitle>
					Jam to Teddy’s latest music by signing in with your email or
					Spotify
				</PageSubtitle>
			</TitleContainer>
			<EmailSectionVertical>
				<EmailSection>
					<EmailIconContainer>
						<Icon src={EmailIcon} alt="Email Icon" />
						<EmailText>EMAIL</EmailText>
					</EmailIconContainer>
					<Input
						type="email"
						placeholder="What is your email address?"
						value={emailForm}
						onChange={(e) => setEmailForm(e.target.value)}
						onKeyDown={handleInputKeyDown}
					/>
					{!emailValid && <WarningText>{emailError}</WarningText>}
				</EmailSection>
				<EmailButton
					src={emailValid ? EmailButtonIcon : EmailButtonIconGrey}
					alt="Email Button"
					id="submit-button"
					onClick={() => handleEmailSave()}
				/>
			</EmailSectionVertical>
			<Line>
				<OrText>OR</OrText>
			</Line>
			<SpotifyContainer onClick={() => handleSpotifyAuth()}>
				<SpotifyInner>
					<SpotifyTitle>Sign in with Spotify</SpotifyTitle>
					<SpotifySubtitle>
						A{" "}
						<span
							style={{
								fontStyle: "italic",
								color: "#FFE664",
							}}
						>
							premium Spotify account
						</span>{" "}
						is required to unlock the full experience
					</SpotifySubtitle>
				</SpotifyInner>
				<SpotifyLogo src={SpotifyLogoIcon2} alt="Spotify Logo" />
			</SpotifyContainer>
		</>
	);
};

export default EmailForm;

const SpotifyContainer = styled.div`
	cursor: pointer;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 50%;
	height: fit-content;
	z-index: 5;
	background: rgba(27, 27, 35, 0.9);
	display: flex;
	align-items: center;
	border: 4px solid rgba(255, 255, 255, 0.2);
	margin-bottom: 2rem;

	@media (max-width: 960px) {
		width: 80%;
		padding: 15px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 80%;
		padding: 5px;
	}
`;
const SpotifyInner = styled.div`
	padding: 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (max-width: 960px) {
		padding: 5px;
		width: 100%;
	}
	@media (min-width: 960px) and (max-width: 1440px) {
		width: 90%;
	}
`;

const WarningText = styled.p`
	font-size: 1rem;
	align-self: center;
	color: #beaeff;
	font-family: "Barlow", sans-serif;
	font-style: italic;
	padding: 0 2rem;

	@media (max-width: 960px) {
		font-size: 0.5rem;
	}
`;

const Line = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	width: 50%;
	margin: 20px 0;
	z-index: 5;

	@media (min-width: 960px) and (max-width: 1440px) {
		margin: 0;
		width: 80%;
	}
	&::before,
	&::after {
		content: "";
		flex: 1;
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}

	&:not(:empty)::before {
		margin-right: 0.5em;
	}

	&:not(:empty)::after {
		margin-left: 0.5em;
	}

	@media (max-width: 960px) {
		width: 80%;
	}
`;
const OrText = styled.h1`
	font-size: 1.5rem;
	margin: 15px;
	color: white;
	font-family: "Barlow", sans-serif;
	font-style: normal;
	font-weight: 600;
	padding: 1rem;

	@media (max-width: 960px) {
		font-size: 1rem;
		padding: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.3rem;
	}
`;
const SpotifyTitle = styled.h1`
	font-size: 1.5rem;
	margin-bottom: -5px;
	color: white;
	font-family: "Barlow", sans-serif;
	font-style: normal;
	font-weight: 600;
	padding-left: 2rem;

	@media (max-width: 960px) {
		font-size: 1.1rem;
		padding-bottom: 10px;
		padding-left: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.3rem;
	}
`;
const SpotifySubtitle = styled.p`
	font-size: 1rem;
	padding-left: 2rem;
	margin-bottom: 20px;
	color: white;
	font-family: "Barlow", sans-serif;

	@media (max-width: 960px) {
		font-size: 0.8rem;
		padding-left: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.1rem;
	}
`;

const SpotifyLogo = styled.img`
	width: 80px;
	height: 80px;
	margin-right: 4rem;

	@media (max-width: 960px) {
		width: 50px;
		height: 50px;
		margin-right: 0rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 70px;
		height: 70px;
	}
`;

const EmailSection = styled.div`
	background: rgba(27, 27, 35, 0.9);
	/* margin-top: 20px; */
	display: flex;
	align-items: center;
	border: 4px solid rgba(255, 255, 255, 0.2);
	padding: 5px;
	width: 50%;
	height: 44px;
	flex-shrink: 0;
	flex: 1;

	@media (max-width: 960px) {
		height: 40px;
		width: 100%;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		height: 45px;
		width: 100%;
	}
`;
const EmailButton = styled.img`
	cursor: pointer;
	height: 60px;
	width: 60px;
	padding-left: 1rem;

	@media (max-width: 960px) {
		height: 50px;
		width: 50px;
		padding-left: 0rem;
		padding-top: 0.7rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		padding-left: 0.5rem;
		height: 85px;
		width: 85px;
	}
`;

const EmailSectionVertical = styled.div`
	display: flex;
	align-items: center;
	width: 50%;
	z-index: 5;
	align-content: center;

	@media (max-width: 960px) {
		height: 100px;
		width: 85%;
		padding-left: 0;
		flex-direction: column;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		width: 80%;
	}
`;

const EmailIconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
	padding: 10px;

	@media (max-width: 960px) {
		height: 10px;
		width: 10px;
		margin: 10px;
		padding: 10px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		height: fit-content;
		width: fit-content;
		margin: 10px;
		padding: 10px;
	}
`;

const EmailText = styled.span`
	font-size: 0.7rem;
	margin-top: 5px;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	color: white;
	@media (max-width: 960px) {
		font-size: 10px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 12px;
		width: 90%;
	}
`;

const Input = styled.input`
	flex: 1;
	background: none;
	border: none;
	outline: none;
	height: 45px;
	/* width: 64px; */
	padding-left: 1rem;
	font-size: 18px;
	font-style: italic;
	font-family: "Barlow", sans-serif;
	color: #9da0a7;
	@media (max-width: 960px) {
		font-size: 12px;
		padding-left: 5px;
		height: 30px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 14px;
		padding-left: 5px;
		height: 35px;
	}

	::placeholder {
		color: #9da0a7;
		opacity: 50%;
		@media (max-width: 1440px) {
			font-size: 14px;
		}
	}
`;

const Icon = styled.img`
	width: 30px;
	height: 30px;

	@media (max-width: 960px) {
		height: 15px;
		width: 15px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		height: 20px;
		width: 20px;
	}

	@media (min-width: 1440px) and (max-width: 2000px) {
		height: 25px;
		width: 25px;
	}
`;

const TitleContainer = styled.div`
	text-align: center;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	z-index: 5;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	margin-top: 6rem;

	@media (max-width: 960px) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		margin-top: 20px;
		margin-bottom: 10px;
	}
`;

const PageTitle = styled.h2`
	font-size: 2rem;
	margin-bottom: 5px;
	font-family: "Barlow", sans-serif;
	font-weight: 600;
	color: #ced1d9;
	@media (max-width: 960px) {
		font-size: 22px;
		padding: 1rem;
	}

	@media (min-height: 0px) and (max-height: 360px) {
		margin-top: 12rem;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1.6rem;
	}
`;

const PageSubtitle = styled.p`
	font-size: 1.2rem;
	margin-bottom: 20px;
	font-weight: 500;
	color: #ced1d9;
	@media (max-width: 960px) {
		font-size: 1rem;
		padding: 0.5rem 1rem;
		margin-bottom: 0px;
	}

	@media (min-width: 960px) and (max-width: 1440px) {
		font-size: 1rem;
	}
`;
