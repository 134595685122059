export const spotifyTracks = {
	TRACK_TEDDYSWIMS_LOSE_CONTROL: "spotify:album:7nacKlk586eLRBSIsrk9DB",
	// Add more cases here
};

export const ALBUM = "ALBUM";
export const TRACK = "TRACK";
export const CONTEXT = "context";
export const OPEN_TEDDY_NOTE = "OPEN_TEDDY_NOTE";
export const OPEN_TEDDY_SELFIE = "OPEN_TEDDY_SELFIE";
export const OPEN_TEDDY_CONTEST = "OPEN_TEDDY_CONTEST";
export const STREAM_SECONDS = 31;
