import React from "react";
import styled from "@emotion/styled";
import ErrorIcon from "../../assets/images//errorSymbol.png";
import X from "../../assets/images//X.png";

const Banner = ({ spotifyNotPremiumAccount, setSpotifyNotPremiumAccount }) => {
	const handleClose = () => {
		setSpotifyNotPremiumAccount(false);
	};

	if (spotifyNotPremiumAccount) {
		setTimeout(() => {
			setSpotifyNotPremiumAccount(false);
		}, 8000);
	}

	return (
		<ErrorBannerContainer
			open={spotifyNotPremiumAccount}
			onClick={() => handleClose()}
		>
			<div className="error-content">
				<div>
					<img
						className="error-icon"
						src={ErrorIcon}
						alt="react logo"
					/>
				</div>
				<div className="error-message-container">
					<p className="error-message">
						We failed to connect to Spotify! Please connect a{" "}
						<span
							style={{
								fontWeight: 700,
								color: "#FFE664",
							}}
						>
							Premium Spotify account
						</span>{" "}
						and try again.
					</p>
				</div>
				<div>
					<button onClick={() => handleClose()} src={X}>
						<img className="x-icon" src={X} alt="react logo" />
					</button>
				</div>
			</div>
		</ErrorBannerContainer>
	);
};

export default Banner;

// Styled component for the error banner
const ErrorBannerContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: rgba(102, 16, 16, 0.5);
	color: white;
	padding: 5px;
	display: ${(props) => (props.open ? "flex" : "none")};
	align-items: center;
	justify-content: center;
	width: 100%;
	z-index: 1000;
	transition: transform 0.3s ease;

	.error-message-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;

		@media (max-width: 960px) {
			justify-content: center;
		}
	}

	.x-icon {
		resizemode: contain;
		height: 35px;
		width: 35px;
		padding-right: 20px;

		@media (max-width: 960px) {
			height: 20px;
			width: 20px;
			padding-right: 10px;
		}
	}

	.error-icon {
		resizemode: contain;
		height: 45px;
		width: 45px;

		@media (max-width: 960px) {
			height: 25px;
			width: 25px;
		}
	}

	.error-message {
		font-size: 1.2rem;
		margin-bottom: -5px;
		color: white;
		font-family: "Barlow", sans-serif;
		font-style: normal;
		font-weight: 400;
		padding-left: 2rem;
		padding-bottom: 2rem;

		@media (max-width: 960px) {
			padding-right: 5px;
			font-size: 0.75rem;
			padding-bottom: 10px;
			padding-left: 5px;
		}
	}

	.error-content {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		button {
			background-color: transparent;
			border: none;
			color: white;
			cursor: pointer;
			font-size: 16px;
			margin-top: 10px;
		}
	}
`;
